import React from 'react'
import PageHeader from '../../layout/PageHeader';
import '../../../styles/pages/ServicePage.scss'
import ContactForm from '../../layout/ContactForm'
import contactInfo from '../../../globalInfo/contactInfo.json'
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import $ from 'jquery';
//thumbs
import brick from '../../../assests/images/service-thumbs/facades/service-thumb-brick-masonry.jpg';
import concrete from '../../../assests/images/service-thumbs/facades/service-thumb-stamped-concrete.jpg';
import stone from '../../../assests/images/service-thumbs/facades/service-thumb-stone-masonry.jpg'
function Facades() {
    let scrollToContact = (e) => {
        //e.preventDefalut();
        $('html,body').animate({
          scrollTop: $("#contact").offset().top
        });
        $("input#first_name").focus();
    }
    $(".learnLink").on('click', function (e) {  
        scrollToContact(e)
    })
    return (
        
        <div id="main">
            <PageHeader title="Veneer/Facade Work" desc="We install and renovate pavers, asphalt, facades, concrete, and more. This includes patios, steps, poolsides, foundations, and driveways!"></PageHeader>
            <section class="wrapper intro">
                <p>A veneer wall is made of a single vertical thickness of masonry; it is designed simply to improve the exterior appearance of the building. Decorative masonry such as stone, brick, or marble may be veneered over common stone masonry, concrete block, reinforced concrete, or steel.</p>
                <p>By far, however, the greatest use of veneer walls is brick veneer on wood frame.</p>
                <p>A facade wall, generally speaking, is the decorative face or exterior frontage of a building.</p>
                <p><strong>Ready to start your project?</strong> <button class="learnLink" onClick={ scrollToContact }>Contact Us</button> for a free quote and consultation.</p>
            </section>
          
            <section class="project-listings">
                <div class="wrapper">
                    <div class="project-list">
                        <div class="project-list-left">
                            <img alt="Brick house" src={brick} />
                        </div>
                        <div class="project-list-right">
                            <h3>Brick Masonry</h3>
                            <p>The natural density and thermal insulating qualities of clay moderate building temperature and reduce energy consumption rates, to provide year round comfort and savings. It's low maintenance, weatherproof, and offers a more uniform design when compared to stone.</p>
                            <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                        </div>
                    </div> 
                    <div class="project-list">
                        <div class="project-list-left">
                            <img alt="Cast Stone Veneers" src={stone} />
                        </div>
                        <div class="project-list-right">
                            <h3>Cast Stone Masonry</h3>
                            <p>Stone tends to be less uniform in shape and size and boasts considerably more variety than bricks offer. Whether quarried or manufactured, stone offers more options for size, shape, and color than brick.</p>
                            <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                        </div>
                    </div> 
                    <div class="project-list">
                        <div class="project-list-left">
                            <img alt="Concrete Steps" src={concrete} />
                        </div>
                        <div class="project-list-right">
                            <h3>Vertical Concrete Overlays</h3>
                            <p>You've seen how decorative stamping can add beauty and drama to concrete floors, sidewalks, driveways and other horizontal surfaces. But did you know you can use similar stamping and texturing techniques to enhance vertical surfaces, such as the walls inside your home or business, a fireplace front or an exterior privacy wall?</p>
                            <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                        </div>
                    </div> 
                    
                </div>
            </section>
            <section id="contact" class="section-green">
                <h2 style={{ color: "white", textAlign: 'center', fontSize: '33px', fontWeight:400}}>Request More Info</h2>
                    <ContactForm></ContactForm>
                <h2 style={{ color: "white", textAlign: 'center', marginTop: '30px', fontSize: '27px', fontWeight: 400 }}>Or call <a style={{color:"white", textDecoration:"none", fontWeight: 500}} href={"tel:"+contactInfo.phone}>{ contactInfo.phone }</a></h2>
            </section>
        </div>                          
    )
}

export default Facades
