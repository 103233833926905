import React from 'react'
import PageHeader from '../../layout/PageHeader';
import '../../../styles/pages/ServicePage.scss'
import ContactForm from '../../layout/ContactForm'
import contactInfo from '../../../globalInfo/contactInfo.json'
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import $ from 'jquery';
//thumbs
import court from '../../../assests/images/service-thumbs/asphalt/service-thumb-asphalt-court.jpg';
import driveway from '../../../assests/images/service-thumbs/asphalt/service-thumb-asphalt-driveway.jpg'

function Asphalt() {
    let scrollToContact = (e) => {
        //e.preventDefalut();
        console.log("hi")
        $('html,body').animate({
          scrollTop: $("#contact").offset().top
        });
        $("input#first_name").focus();
    }
    $(".learnLink").on('click', function (e) {  
        console.log("hi")
        scrollToContact(e)
    })
    return (
        
        <div id="main">
            <PageHeader title="Asphalt Work" desc="We install and renovate pavers, asphalt, facades, concrete, and more. This includes patios, steps, poolsides, foundations, and driveways!"></PageHeader>
            <section class="wrapper intro">
                
                <p>With few exceptions, asphalt is easier on the budget than concrete. Typical installation costs for a new asphalt driveway run about $2 to $4 per square foot, although prices can go higher depending on crude oil prices.</p>
                <p>While freezing Jersey winters can take a toll driveways of any material, concrete is more likely to crack from constant freezing and thawing, while road salt eats away at concrete surface. This makes aspahlt a great option for many of our customers.</p>
                <p><strong>Ready to start your project?</strong> <button class="learnLink" onClick={ scrollToContact }>Contact Us</button> for a free quote and consultation.</p>
            </section>
          
            <section class="project-listings">
                <div class="wrapper">
                    <div class="project-list">
                        <div class="project-list-left">
                            <img alt="Asphalt Driveway" src={ driveway}/>
                        </div>
                        <div class="project-list-right">
                            <h3>Driveways</h3>
                            <p>Climate is a major consideration when choosing between asphalt and concrete. It takes snow and ice longer to melt on concrete than asphalt. While asphalt is easier to repair than concrete, asphalt needs it more frequently.</p>
                            {/* <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button> */}
                            <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                        </div>
                    </div>     
                    <div class="project-list">
                        <div class="project-list-left">
                            <img alt="Concrete Steps" src={court} />
                        </div>
                        <div class="project-list-right">
                            <h3>Lots and Courts</h3>
                            <p>Over 95% of all tennis courts are “hard” courts. This usually means that they are made with 2 layers of asphalt, totaling 5 inches depth, placed on top of a 6 inches crushed limestone base. The asphalt surface is then coated with 2 coats of a filler material and 3 coats of acrylic tennis court paint. The installation process is very similar to that of an asphalt parking lot.</p>
                            {/* <a href="#" class="learnLink">Learn More<ChevronRightIcon/></a> */}
                            <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                        </div>
                    </div> 
                </div>
            </section>
            <section id="contact" class="section-green">
                <h2 style={{ color: "white", textAlign: 'center', fontSize: '33px', fontWeight:400}}>Request More Info</h2>
                    <ContactForm></ContactForm>
                <h2 style={{ color: "white", textAlign: 'center', marginTop: '30px', fontSize: '27px', fontWeight: 400 }}>Or call <a style={{color:"white", textDecoration:"none", fontWeight: 500}} href={"tel:"+contactInfo.phone}>{ contactInfo.phone }</a></h2>
            </section>
        </div>                          
    )
}

export default Asphalt
