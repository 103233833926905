import React, { useState } from 'react';
import "../../styles/ContactForm.scss";
//import ReCAPTCHA from 'react-google-recaptcha';
//import CheckBox from '../layout/CheckBox'
import axios from 'axios';
import moment from 'moment';
import $ from 'jquery'
import LoadingOverlay from '../layout/LoadingOverlay'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SuccessCheck from '../layout/SuccessCheck';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';
import ReactGA from 'react-ga';
//TODO: verify 
//https://developers.google.com/recaptcha/docs/verify


const ContactForm = () => {

    function getSubmitted() {
        let submitted = localStorage.getItem("contact_form_submitted");
        if (submitted === "true") {
            return true
        } else {
            return false
        }
    }
    
    
    //const fieldsToValidate = ["first_name","last_name","city","email","phone",]
    let [clientInfo, updateInfo] = useState({
        first_name: "",
        last_name: "",
        city: "",
        email: "",
        phone: "",
        //projectType: "residential",
        //company_name: "",
        request_body: "",
        create_account: false,
        date_time: new Date(),
        submitted: getSubmitted(), // true/false/"pending" - after click, before server res
        error:false
    });

    let onChange = (e) => {
        updateInfo({
            ...clientInfo,
            [e.target.name]: e.target.value
        })
    }

    // const recaptchaRef = React.useRef();
 
    // const onSubmitWithReCAPTCHA = async () => {
    //     const token = await recaptchaRef.current.executeAsync();
    
    //     // apply to form data
    // }
    const showFieldError = (errmsg, thefield) => {
        let errorPresent = $(`input#${thefield},textarea#${thefield}`).parent().find(".error");
        if (!errorPresent.length) {
            $(`input#${thefield},textarea#${thefield}`).addClass('error');
            $(`input#${thefield},textarea#${thefield}`).parent().append(`<span class='error' style="margin-top: -10px;font-size:12px;margin-bottom: -5px;">${errmsg}</span>`);
        } else if (errorPresent.length) {
            
            let prevError = $(errorPresent[0].outerHTML).html();
            //console.log("prevError: ", prevError)
            
            if (prevError !== errmsg) {
                $(`input#${thefield},textarea#${thefield}`).addClass('error');
                $(`input#${thefield},textarea#${thefield}`).parent().find(".error").html(errmsg)
            }
            // $(`input#${thefield}`).parent().append(`<span class='error' style="font-size:13px;">${errmsg}</span>`);
        }
        
        updateInfo({
            ...clientInfo,
            submitted: false
        });
        
    }
    let valiteForm = () => {
        let allPassed = true;
        for (let field in clientInfo) {
            //console.log(`field: ${field} is `, clientInfo[field]);
            let value = clientInfo[field];
            if (value.constructor.name === "String") {
                let errmsg;
                if (value.length < 3 && value !== "") {
                    errmsg = "Please enter at least 3 characters.";
                    showFieldError(errmsg, field);
                    allPassed = false;
                    
                   
                } else if (value === "") {
                    errmsg = "This felid is required."
                    showFieldError(errmsg, field);
                    allPassed = false;
                    
                    
                } else {
                    $(`input#${field},textarea#${field}`).removeClass("error");
                    //console.log($(`input#${field},textarea#${field}`).parent().find(".error").remove())
                }
            }
        }
        ReactGA.event({
            category: "Contact Form Submission",
            action: "User filled and submitted contact form",
        });
        return allPassed
    }

    const onSubmit = async function (e) {
        e.preventDefault();
        updateInfo({
            ...clientInfo,
            submitted: "pending"
        });

        if (valiteForm()) {
            //console.log("clientInfo", clientInfo);
            // let info = JSON.stringify(clientInfo);
            // console.log(info);
            clientInfo.date_time = moment().format("ddd, MMM D YYYY, h:mm A");
            const options = {
                url: 'https://ogc-construction.herokuapp.com/forms/contact',
                method: 'POST',
                //headers: { "content-type": "application/x-www-form-urlencoded",},
                data: clientInfo
            };
            try {
                let res = await axios(options)
                if (res.status === 200) {
                    //change form state to "submitted"
                    //save state
                    updateInfo({
                        ...clientInfo,
                        submitted: true,
                        error: false
                    });
                    localStorage.setItem("contact_form_submitted", "true");
        
                    
                } else {
                    //backend sends notification email to kenny
                    //change form state to "error"
                    updateInfo({
                        ...clientInfo,
                        submitted: true,
                        error:true
                    })
                }
            } catch (error) {
                //console.log(error);
                updateInfo({
                    ...clientInfo,
                    submitted: false,
                    error: true
                });
                $('html,body').animate({
                    scrollTop: $("#contact").offset().top
                });
            }
        }

        
        
          
        
    }
    if (clientInfo.error === false && clientInfo.submitted === true) {
        return (
            <form id="contactform">
                <div id="confirmationMsg">
                    <p style={{fontWeight: 600, fontSize: '20px', marginBottom: '34px'}}>Thank you for contacting us!</p>
                        <SuccessCheck></SuccessCheck>
                    <p style={{ fontSize: "18px", marginBottom: "12px", marginTop: "-2px"}}>We'll be in touch shortly.</p>
                </div>
            </form>
        )
    } else {
        return (
            <form id="contactform" onSubmit={onSubmit}>  
                
                {clientInfo.submitted === "pending" && (
                        <LoadingOverlay></LoadingOverlay>
                    ) 
                }
                {clientInfo.error === true && (
                        <span class="error"><ErrorOutlineIcon></ErrorOutlineIcon> There was a problem submitting your request. <br/>Please give us a call at the number below!</span>
                    ) 
                }
                <div class="input-group">
                    <label for="first_name">First Name</label>
                    <input type="text" id="first_name" name="first_name" placeholder="" onChange={onChange} minlength="2" maxlength="20" />
                </div>
                <div class="input-group">
                    <label for="last_name">Last Name</label>
                    <input type="text" id="last_name" name="last_name" placeholder="" onChange={onChange} minlength="2" maxlength="20" />
                </div>
                <div class="input-group">
                    <label for="city">City</label>
                    <input type="text" id="city" name="city" placeholder=""  onChange={onChange} minlength="2" maxlength="20"/>
                </div>
                <div class="input-group">
                    <label for="email">Email</label>
                    <input type="email" id="email" name="email" placeholder="" onChange={onChange} minlength="2" maxlength="20" />
                </div>
                <div class="input-group">
                    <label for="phone">Phone</label>
                    <input type="tel" id="phone" name="phone" placeholder="" onChange={onChange} minlength="2" maxlength="20" />
                </div>
                {/* <p>What type of project is this regarding?</p>
                <div class="input-group inline-group">
                    
                    <label for="res">Residential</label>
                    <input type="radio" name="projectType" value="residential" id="res" onChange={onChange} checked={clientInfo.projectType === "residential"} />
                </div>
                <div class="input-group inline-group">
                    <label for="com">Commercial</label>
                    <input type="radio" name="projectType" value="commercial" id="com" onChange={onChange} checked={ clientInfo.projectType === "commercial"}/>
                </div>
                {clientInfo.projectType === "commercial" && 
                    <div class="input-group">
                        <label for="company_name">Company Name</label>
                        <input type="text" id="company_name" name="company_name"  onChange={onChange}/>
                    </div>
                } */}
                
                <div class="input-group">
                    <label for="request_body">How can we help?</label>
                    <textarea type="textarea" id="request_body" name="request_body" placeholder="i.e. &apos;I'd like to schedule an appointment for a free quote.&apos;" onChange={onChange} minlength="2" maxlength="250"/>
                </div>
                {/* <div class="input-group">
                    <label for="create-account">Create a free account for me.</label>
                    <CheckBox id="create-account" name="create-account" onChange={onChange}></CheckBox>
                    {/* <input checked type="checkbox" id="create-account" name="create-account" onChange={onChange}/> 
                </div> */}
                {/* <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LfKVEkaAAAAAO7slyryRLUX45nWXnA6d2WMVOvI"
                    size="invisible"
                /> */}
                <div style={{ textAlign: "right", position:"relative"}}>
                    {/* <input type="submit" value="Send" /> */}
                    <Button className="submitBtn" onClick={onSubmit}>Send<ChevronRightIcon /></Button>
                    
                </div>
                
                
                </form>
        );
    }
    
    
}

export default ContactForm;
