import React from 'react'
import $ from 'jquery';
import '../../styles/Microstory.scss'
function Microstory(props) {
    let { image, content, author, city } = props
    let onClick = (e) => {
        $('html,body').animate({
            scrollTop: $("#contact").offset().top
          });
          $("input#first_name").focus();
    }
    return (
        <div class="microstory microstory-quote">
            <div class="microstory-image" style={{ backgroundImage: `url(${image})`}} role="img" aria-label="testimonial image"></div>
        <div class="microstory-content">
            <div class="microstory-primary">
            
            
                <p>{ content }</p>
                <div class="footer">
                    <div class="caps">{ author }</div>
                    <div class="gray-dark">{city}</div>
                    {/* <a href="/customers/litcharts">Read customer story →</a> */}
                    {/* <a class="contactLink" onClick={onClick} href="#">Contact us →</a> */}
                    <button class="contactLink" onClick={onClick}>Contact us →</button>
                    
                </div>
                

            </div>
        </div>
        </div>
    )
}

export default Microstory
