import React from 'react';
import PageHeader from '../layout/PageHeader';
import Slider from '../layout/Slider'
import ContactForm from '../layout/ContactForm'
import contactInfo from '../../globalInfo/contactInfo.json'
import '../../styles/pages/Portfolio.scss'
import InstaGallery from '../layout/InstaGallery'
import Button from "@material-ui/core/Button";
import ChevronRight from '@material-ui/icons/ChevronRight';
import SocialLinks from '../layout/SocialLinks'
export const Portfolio = () => {
   
    return (
        <div id="main" class="Portfolio">
            <PageHeader title="Our Work" desc="Take a glance at our passed projects in your area. Get inspiration for your own custom outdoor space."></PageHeader>
            <section id="gallery">
                <div className="wrapper" style={{marginTop: "-50px"}}>
                    <h2 style={{color: 'rgb(56 115 52)', fontWeight: 600, fontSize: '30px'}}>We look beyond your immediate construction needs to develop comprehensive solutions for each project.</h2>
                    <p style={{fontSize: '20px'}}>Our craftsmanship has been rewarded by hundreds of referrals and satisfied customers throughout the New Jersey area. Standing behind a solid reputation, we at OGC have worked to provide their clients not just contracting services but a true experience in high-end quality at a resonable price.</p>
                    <p style={{fontSize: '20px'}}>We provide on the spot estimates, start and finish the work on time, and often leave as friends rather than contractors.  Our clients have shared with us that our work has changed how they view their outdoor living spaces, and are consistently satisfied with our solutions.</p>
                    <div style={{padding:"20px 0px"}}>
                        {/* <InstaGallery /> */}
                    </div>
                    
                    <div style={{textAlign:'center'}}>
                        <a href="https://www.instagram.com/ogcconstruction" target="_blank"><Button>More Photos <ChevronRight style={{color:'white'}}/></Button></a>
                    </div>
                </div>
            </section>
            <div id="customers">
                <div class="testimonials-container section-gray-dark">
                    <div id="testimonials" class="section-green">
                        <Slider></Slider>
                    </div>
                </div>
            </div>
            <section id="social">
                <h2 style={{color: "#252525", fontSize: "30px",paddingBottom: "4px"}}>Need Inspiration?</h2>
                <p style={{maxWidth: '700px',margin: 'auto',marginBottom: '20px',marginTop: -'30px',fontWeight: '400', padding: '0px 20px'} }>Whether you're looking to design a patio with pavers, brick, or poured concrete, choosing a design and material to match you're current property. Be sure to visit our pages on Facebook, Instagram, and Pintrest for inspiration. Send a direct message with any questions as well!</p>
                <p style={{fontWeight: '700',fontSize: '18px', color: '#235420',padding: '0px 20px'}}>Follow us on Instagram and like our Facebook page, get 5% off your initial estimate.</p>
                <div style={{textAlign:'center'}}>
                    <SocialLinks originalColor={true}/>
                </div>
            </section>
            <section id="contact">
                <h2 style={{ color: "white", textAlign: 'center', fontSize: '33px', fontWeight:400}}>Request More Info</h2>
                <ContactForm></ContactForm>
                <h2 style={{ color: "white", textAlign: 'center', marginTop: '30px', fontSize: '27px', fontWeight: 400 }}>Or call <a style={{color:"white", textDecoration:"none", fontWeight: 500}} href={"tel:"+contactInfo.phone}>{ contactInfo.phone }</a></h2>
            </section>
        </div>
    )
}