import React, { useState, useEffect } from 'react';
import logoGreen from '../../assests/images/ogc-construction/svg/ogc-construction-green.svg';
import logoWhite from '../../assests/images/ogc-construction/svg/ogc-construction-white.svg';
import '../../styles/Navbar.scss';
// import Button from "@material-ui/core/Button";
import { useLocation } from 'react-router-dom'
import PhoneIcon from '@material-ui/icons/WhatsApp';
import contactInfo from "../../globalInfo/contactInfo.json";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'
import {withRouter} from 'react-router'
import { Nav, Navbar } from 'react-bootstrap';
import $ from 'jquery'

export const NavbarBS = () => {
    let location = useLocation();

    var onResize = function(defaultt) {
        // apply dynamic padding at the top of the body according to the fixed navbar height
        //console.log("Ajusted padding!!!")
        let bodyTopPadding = (defaultt||$("nav.fixed-top").height()) + 20;
        Number(bodyTopPadding) > 87 ? bodyTopPadding = 87 : bodyTopPadding = bodyTopPadding
        $("body").css("padding-top", bodyTopPadding);
      };
      
      // attach the function to the window resize event
      $(window).on('resize', onResize);
      
      // call it also when the page is ready after load or reload
      $(function() {
        onResize(87);
      });
    
    const [theme, setTheme] = useState({
        color: "navbar-light",
        logo: logoGreen,
        linksDisplayed: true,
        expanded: false,
        scrolled: false
    });
    const closeNav = () => {
        // console.log("CLOSE NAV BEFORE: ", theme.expanded)
        setTheme({
            ...theme,
            expanded:false
        })
        //console.log("CLOSE NAV AFTER: ", theme.expanded)
    }
    useEffect(
        () => {
            onResize();
            //ga.send(['pageview', location.pathname])
            if (location.pathname === "/") {
                setTheme({
                    ...theme,
                    color: "navbar-light",
                    logo: logoGreen,
                    linksDisplayed: true
                });
            } else if (location.pathname === "/maintenance") {
                
                setTheme({
                    ...theme,
                    color: "navbar-dark",
                    logo: logoWhite,
                    linksDisplayed: false
                })
            } else {
                if ($("a.nav-link:nth-child(1)").hasClass('active')) {
                    $("a.nav-link:nth-child(1)").removeClass('active')
                }
                setTheme({
                    ...theme,
                    color: "navbar-dark",
                    logo: logoWhite,
                    linksDisplayed: true
                })
            }
        },
        [location]
    )
   
    return (
        <Navbar expanded={theme.expanded} className={`${theme.color} ${theme.scroll ? "scrolled" : ""}`} fixed="top" expand="lg">
            <LinkContainer onClick={closeNav} to="/">
                <Navbar.Brand ><div id="logo"><Link to="/"><img src={theme.logo} alt={ contactInfo.company_name }></img></Link></div></Navbar.Brand>
            </LinkContainer>
            
            <Navbar.Toggle onClick={() => {
                setTheme({
                    ...theme,
                    expanded: theme.expanded ? false : "expanded"
                })
            }} aria-controls="basic-Navbar-nav" />
            <Navbar.Collapse id="basic-Navbar-nav" >
                <Nav activeKey={location.pathname} className="mr-auto">
                    <LinkContainer onClick={closeNav} to="/">
                        <Nav.Link>Home</Nav.Link>
                    </LinkContainer>
                    <LinkContainer onClick={closeNav} to="/services">
                        <Nav.Link>Services</Nav.Link>
                    </LinkContainer>
                    <LinkContainer onClick={closeNav} to="/portfolio">
                        <Nav.Link>Our Work</Nav.Link>
                    </LinkContainer>
                    {/* <LinkContainer onClick={closeNav} to="/portfolio">
                        <Nav.Link>Our Work</Nav.Link>
                    </LinkContainer>
                    <NavDropdown title="About Us" id="basic-nav-dropdown">
                        <LinkContainer onClick={closeNav} to="/about#life-at-ogc">
                            <NavDropdown.Item>Life at OGC</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer onClick={closeNav} to="/about#service-area">
                            <NavDropdown.Item>Service Area</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer onClick={closeNav} to="/about#careers">
                            <NavDropdown.Item>Careers</NavDropdown.Item>
                        </LinkContainer>
                    </NavDropdown> */}
                </Nav>
                <Navbar.Text>
                    <a class="callLink" target="_blank" rel="noreferrer" href="https://wa.me/+19082918829"><PhoneIcon></PhoneIcon><span>{ contactInfo.phone}</span></a>
                </Navbar.Text>
            </Navbar.Collapse>
      </Navbar>
    )
}