import React from 'react'
import PageHeader from '../../layout/PageHeader';
import '../../../styles/pages/ServicePage.scss'
import ContactForm from '../../layout/ContactForm'
import contactInfo from '../../../globalInfo/contactInfo.json'
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import $ from 'jquery';
//thumbs
import concrete from '../../../assests/images/service-thumbs/concrete/service-thumb-concrete-walkway.jpg';
import pavers from '../../../assests/images/service-thumbs/pavers/service-thumb-pavers-walkway.jpg';
function Walkways() {
    let scrollToContact = (e) => {
        //e.preventDefalut();
        $('html,body').animate({
          scrollTop: $("#contact").offset().top
        });
        $("input#first_name").focus();
    }
    $(".learnLink").on('click', function (e) {  
        scrollToContact(e)
    })
    return (
        
        <div id="main">
            <PageHeader title="Walkways and Sidewalks" desc="We install and renovate pavers, asphalt, facades, concrete, and more. This includes patios, steps, poolsides, foundations, and driveways!"></PageHeader>
            <section class="wrapper intro">
                <p>Concrete walkways or sidewalks are more than just a way to get wherever it is you're going—they can provide much-needed curb appeal to homes and buildings, and they are fast becoming a point of artistic expression by way of a multitude of decorative concrete options.</p>
                <p>There are a variety of concrete services available for residential and commercial construction. Get more information about each of these types of concrete work:</p>
                <p><strong>Ready to start your project?</strong> <button class="learnLink" onClick={ scrollToContact }>Contact Us</button> for a free quote and consultation.</p>
            </section>
          
            <section class="project-listings">
                <div class="wrapper">
                    
                <div class="project-list">
                    <div class="project-list-left">
                        <img alt="Concrete Sidewalk" src={concrete} />
                    </div>
                    <div class="project-list-right">
                        <h3>Concrete Walkways</h3>
                        <p>While plain gray concrete is still the most often surface installed, as seen on most concrete sidewalks, there are numerous decorative concrete alternatives sure to dress it up, taking your sidewalk from boring to amazing.</p>
                        <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                    </div>
                </div> 
                <div class="project-list">
                    <div class="project-list-left">
                        <img alt="Sidewalk" src={pavers} />
                    </div>
                    <div class="project-list-right">
                        <h3>Paver Walkways</h3>
                        <p>Pavers give you the versatility to form walkways with gentle curved configurations and make them any width you desire. They also allow you to replicate the traditional look of a natural stone or brick walkway, but come in a wider array of colors and shapes.</p>
                        <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                    </div>
                </div> 
                </div>
            </section>
            <section id="contact" class="section-green">
                <h2 style={{ color: "white", textAlign: 'center', fontSize: '33px', fontWeight:400}}>Request More Info</h2>
                    <ContactForm></ContactForm>
                <h2 style={{ color: "white", textAlign: 'center', marginTop: '30px', fontSize: '27px', fontWeight: 400 }}>Or call <a style={{color:"white", textDecoration:"none", fontWeight: 500}} href={"tel:"+contactInfo.phone}>{ contactInfo.phone }</a></h2>
            </section>
        </div>                          
    )
}

export default Walkways
