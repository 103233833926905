import React from 'react'
import PageHeader from '../../layout/PageHeader';
import '../../../styles/pages/ServicePage.scss'
import ContactForm from '../../layout/ContactForm'
import contactInfo from '../../../globalInfo/contactInfo.json'
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import $ from 'jquery';
//thumbs
import concrete from '../../../assests/images/service-thumbs/concrete/service-thumb-concrete-steps.jpg';
import precast from '../../../assests/images/service-thumbs/steps/service-thumb-precast-steps.jpg';
import pavers from '../../../assests/images/service-thumbs/steps/service-thumb-paver-steps.jpg'
function Steps() {
    let scrollToContact = (e) => {
        //e.preventDefalut();
        $('html,body').animate({
          scrollTop: $("#contact").offset().top
        });
        $("input#first_name").focus();
    }
    $(".learnLink").on('click', function (e) {  
        scrollToContact(e)
    })
    return (
        
        <div id="main">
            <PageHeader title="Steps and Stairs" desc="We install and renovate pavers, asphalt, facades, concrete, and more. This includes patios, steps, poolsides, foundations, and driveways!"></PageHeader>
            <section class="wrapper intro">
                <p>Concrete steps or terraced stairways are the most prominent feature at the entryway of many homes and public buildings, yet too often they get relegated to purely utilitarian status. Elevating concrete stairs from bland to grand makes them the dramatic focal point of any front entrance.</p>
                <p>There are a variety of concrete services available for residential and commercial construction. Get more information about each of these types of concrete work:</p>
                <p><strong>Ready to start your project?</strong> <button class="learnLink" onClick={ scrollToContact }>Contact Us</button> for a free quote and consultation.</p>
            </section>
          
            <section class="project-listings">
                <div class="wrapper">
                <div class="project-list">
                        <div class="project-list-left">
                            <img alt="Concrete Steps" src={concrete} />
                        </div>
                        <div class="project-list-right">
                            <h3>Concrete Steps</h3>
                            <p>Concrete steps eventually break up, especially in northern climates. Water soaks into the concrete, freezes and breaks off the outside corners. Cutting away the damaged concrete and pouring a new concrete cap on the step can often repair badly broken concrete steps. The fix will be durable and you avoid the high expense of a complete tear-out.</p>
                            <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                        </div>
                    </div> 
                    <div class="project-list">
                        <div class="project-list-left">
                            <img alt="Stamped Concrete Walkway" src={precast} />
                        </div>
                        <div class="project-list-right">
                            <h3>Precast Steps and Stairs</h3>
                            <p>Precast concrete steps and precast concrete stairs are also an option. If you know just where they are needed, you can have the steps built off-site and installed later on your project.</p>
                            <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                        </div>
                    </div> 
                    <div class="project-list">
                        <div class="project-list-left">
                            <img alt="Pavers for steps and stairs" src={pavers} />
                        </div>
                        <div class="project-list-right">
                            <h3>Pavers for Stairs</h3>
                            <p>Modular pavers are an extremely versatile, cost-effective material for building outdoor patios, pathways and stairs. With basic tools and a general understanding of the project, you can enhance the beauty and functionality of your outdoor space.J</p>
                            <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                        </div>
                    </div> 
                </div>
            </section>
            <section id="contact" class="section-green">
                <h2 style={{ color: "white", textAlign: 'center', fontSize: '33px', fontWeight:400}}>Request More Info</h2>
                    <ContactForm></ContactForm>
                <h2 style={{ color: "white", textAlign: 'center', marginTop: '30px', fontSize: '27px', fontWeight: 400 }}>Or call <a style={{color:"white", textDecoration:"none", fontWeight: 500}} href={"tel:"+contactInfo.phone}>{ contactInfo.phone }</a></h2>
            </section>
        </div>                          
    )
}

export default Steps
