import React from 'react';
import './styles/App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './components/other/ScrollToTop';
import { NavbarBS } from './components/layout/NavbarBS';
import { Footer } from './components/layout/Footer';

//import { Maintenance } from './components/pages/Maintenance';
import { Home } from './components/pages/Home';
import { Services } from './components/pages/Services';
//service pages
import Asphalt from './components/pages/services/Asphalt';
import Borders from './components/pages/services/Borders';
import Concrete from './components/pages/services/Concrete';
import Driveways from './components/pages/services/Driveways';
import Facades from './components/pages/services/Facades';
import Firepits from './components/pages/services/Firepits';
import Patios from './components/pages/services/Patios';
import Pavers from './components/pages/services/Pavers';
import PoolDecks from './components/pages/services/PoolDecks';
import Steps from './components/pages/services/Steps';
import Walkways from './components/pages/services/Walkways';
import Walls from './components/pages/services/Walls';
//service pages end
import { Portfolio } from './components/pages/Portfolio';
import { About } from './components/pages/About';
import { Contact } from './components/pages/Contact';
import { NotFound } from './components/pages/NotFound';
import TermsOfService from './components/pages/policy/TermsOfService';
import Privacy from './components/pages/policy/Privacy';
import Cookies from './components/pages/policy/Cookies';

import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

function App() {

  ReactGA.initialize('UA-266518396-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  const history = createBrowserHistory();

  // Initialize google analytics page view tracking
  history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

  return (
    <div className="App">
      
      <Router history={history}>
        <NavbarBS currentPage={window.location.pathname} props></NavbarBS>
        <ScrollToTop />
        <Switch>
          {/* <Route exact path='*' component={Maintenance}></Route> */}
          <Route exact path='/' component={Home}></Route>
          <Route exact path='/services' component={Services}></Route>
          {/* Service Pages */}
          <Route exact path='/services/concrete' component={Concrete}></Route>
          <Route exact path='/services/pavers' component={Pavers}></Route>
          <Route exact path='/services/patios' component={Patios}></Route>
          <Route exact path='/services/walkways' component={Walkways}></Route>
          <Route exact path='/services/walls' component={Walls}></Route>
          <Route exact path='/services/pooldecks' component={PoolDecks}></Route>
          <Route exact path='/services/driveways' component={Driveways}></Route>
          <Route exact path='/services/firepits' component={Firepits}></Route>
          <Route exact path='/services/borders' component={Borders}></Route>
          <Route exact path='/services/steps' component={Steps}></Route>
          <Route exact path='/services/facades' component={Facades}></Route>
          <Route exact path='/services/asphalt' component={Asphalt}></Route>
          {/* Service Pages END */}

          <Route exact path='/portfolio' component={Portfolio}></Route>
          <Route exact path='/about' component={About}></Route>
          <Route exact path='/contact' component={Contact}></Route>
          <Route exact path='/policy/terms-of-service' component={TermsOfService}></Route>
          <Route exact path='/policy/privacy' component={Privacy}></Route>
          <Route exact path='/policy/cookies' component={Cookies}></Route>
          <Route component={NotFound}></Route>
        </Switch>
        <Footer></Footer>
      </Router>
      
    </div>
  );
}

export default App;
