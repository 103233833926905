import React from 'react';
import PageHeader from '../layout/PageHeader';

export const About = (props) => {
    return (
        <div id="main">
            <PageHeader title="About Us"></PageHeader>

            <div>
                <iframe title="serviceArea" src="https://www.google.com/maps/d/embed?mid=1-fsdKRdj5N_1z3euvv-nqPYOsztRTMt_" width="640" height="480"></iframe>
            </div>
        </div>
    )
}