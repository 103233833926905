import React from 'react'
import Spinner from '../layout/Spinner';
import '../../styles/LoadingOverlay.scss';

function LoadingOverlay() {
    return (
        <div id="overlay">
            <Spinner></Spinner>
        </div>
    )
}

export default LoadingOverlay
