import React from 'react'
import ReactVivus from 'react-vivus';
import Snap from 'snapsvg-cjs';
import Easing from 'easing-functions';
//import logoGreen from '../../assests/images/ogc-construction/svg/ogc-construction-green-inline-no-construction.svg';
import logoGreen from "../../assests/images/ogc-construction/svg/ogc-construction-green-outline-separate-paths-compound-2-layers.svg";

function LogoAnimation(props) {
    const start = () => {
        //console.log("Letters are filled. Start aimation.")
    }
    let containerStyle = {
        ...props.style,
        height: '100px'
    }
    return (
        
                    <ReactVivus
                        id="logoContainer"
                        option={{
                            file: logoGreen,
                            animTimingFunction: 'EASE',
                            duration: 200,
                            type: 'delayed',
                            onReady: () => {
                                //console.log("vivus ready")
                            }
                        }}
                        style={containerStyle}
                        callback={() => {
                            //console.log("CALLBACK CALLED")
                            const s = Snap("#logoContainer svg");
                            const paths = s.selectAll("#Layer_2 path");
                            
                            //console.log("paths", paths);
                            
                            paths.attr({
                                 fill: "white"
                            });
                            //console.log("START FILLING")
                            paths.forEach(function (letter, index) {
                                //console.log("letter, ", letter)
                                //console.log("fill it !!!")
                                setTimeout(function() {
                                    letter.animate({
                                        fill: "#265D0C"
                                    }, 500, Easing.Quadratic.Out);
                                    if (index >= paths.length - 1) {
                                        start();
                    
                                    }
                                }, 100 * index);
                            });
                        }}
                    />    
        
    )
}

export default LogoAnimation
