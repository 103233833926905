import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import PinterestIcon from '@material-ui/icons/Pinterest';
import InstagramIcon from '@material-ui/icons/Instagram';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import YouTubeIcon from '@material-ui/icons/YouTube';

//color svgs
//import FacebookIconColor from '../../assests/images/social-icons/facebook.svg';
import FacebookIconColor from '../../assests/images/social-icons/facebook.png';
import PinterestIconColor from '../../assests/images/social-icons/pinterest.svg'
import InstagramIconColor from '../../assests/images/social-icons/instagram.svg'

import "../../styles/SocialLinks.scss";
import clientInfo from "../../globalInfo/contactInfo.json"


function SocialLinks(props) {
    let { originalColor, solidColor } = props;
    return (
        <div id="socialLinks">
            <ul style={{padding:'0px'}}>
                <li><a rel="noreferrer" href={clientInfo.social_links.facebook} target="_blank">
                    {originalColor === true ? <img alt="Facebook logo" src={ FacebookIconColor } className="colorIcon"/>  : <FacebookIcon style={{color:solidColor}} />}
                </a></li>
                <li><a rel="noreferrer" href={clientInfo.social_links.instagram} target="_blank">
                    {originalColor === true ? <img  alt="Instagram logo" src={ InstagramIconColor } className="colorIcon"/> : <InstagramIcon style={{color:solidColor}} />}
                </a></li>
                <li><a rel="noreferrer" href={clientInfo.social_links.pintrest} target="_blank">
                    {originalColor === true ? <img  alt="Pinterest logo" src={ PinterestIconColor } className="colorIcon"/> : <PinterestIcon style={{color:solidColor}} />}
                </a></li>
            </ul>
        </div>
    )
}
SocialLinks.defaultProps = {
    originalColor:false
}
export default SocialLinks