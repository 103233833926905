import React from 'react'
import PageHeader from '../../layout/PageHeader';
import '../../../styles/pages/ServicePage.scss'
import ContactForm from '../../layout/ContactForm'
import contactInfo from '../../../globalInfo/contactInfo.json'
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import $ from 'jquery';
//thumbs
import curbconc from '../../../assests/images/service-thumbs/curbs/service-thumb-concrete-curb.jpg';
import curbmason from '../../../assests/images/service-thumbs/curbs/service-thumb-masonry-curb.jpg'
export default function Borders() {
    let scrollToContact = (e) => {
        //e.preventDefalut();
        $('html,body').animate({
          scrollTop: $("#contact").offset().top
        });
        $("input#first_name").focus();
    }
    $(".learnLink").on('click', function (e) {  
        scrollToContact(e)
    })
    return (
        
        <div id="main">
            <PageHeader title="Curbs and Borders" desc="We install and renovate pavers, asphalt, facades, concrete, and more. This includes patios, steps, poolsides, foundations, and driveways!"></PageHeader>
            <section class="wrapper intro">
                <p>Concrete curbing is a great way to add a special touch that transforms your homes' landscape. This type of permanent landscape edging can really improve the curb appeal of your home. There are a variety of options to choose from giving you the opportunity to be creative when taking your yard to the next level.</p>
                <p>There are a variety of concrete services available for residential and commercial construction. Get more information about each of these types of concrete work:</p>
                <p><strong>Ready to start your project?</strong> <button class="learnLink" onClick={ scrollToContact }>Contact Us</button> for a free quote and consultation.</p>
            </section>
          
            <section class="project-listings">
            <div class="wrapper">
                <div class="project-list">
                    <div class="project-list-left">
                        <img alt="Concrete Curbing" src={curbconc} />
                    </div>
                    <div class="project-list-right">
                        <h3>Concrete Curbing</h3>
                        <p>Concrete landscape curbing is the perfect solution to a long time problem. Extruded concrete can follow any curves and designs that your landscape may have. It's not like trying to bend metal to look like a curve.</p>
                        <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                    </div>
                </div> 
                <div class="project-list">
                    <div class="project-list-left">
                        <img alt="Concrete Steps" src={curbmason} />
                    </div>
                    <div class="project-list-right">
                        <h3>Masonry Border Curb</h3>
                        <p>A curb is a masonry border or row of joined bricks or stones forming part of a gutter along the edge of a street.  A curb is the edge where a raised sidewalk meets the lower street level.</p>
                        <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                    </div>
                </div> 
                
                </div>
            </section>
            <section id="contact" class="section-green">
                <h2 style={{ color: "white", textAlign: 'center', fontSize: '33px', fontWeight:400}}>Request More Info</h2>
                    <ContactForm></ContactForm>
                <h2 style={{ color: "white", textAlign: 'center', marginTop: '30px', fontSize: '27px', fontWeight: 400 }}>Or call <a style={{color:"white", textDecoration:"none", fontWeight: 500}} href={"tel:"+contactInfo.phone}>{ contactInfo.phone }</a></h2>
            </section>
        </div>                          
    )
}
