import React from 'react'
import '../../styles/Spinner.scss'
function Spinner() {
    return (
        <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default Spinner
