import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/Card.scss';
import imgPlaceholder from "../../assests/images/image-not-found.png";
import Button from "@material-ui/core/Button";
import { Link } from 'react-router-dom';
function Card(props) {
                
    let { imgSrc, title, desc, pagename, btnText } = props;
    return (
        <div class="card">
            <img alt={`${title} background`} src={ imgSrc } />
            <div class="info">
                <h1>{ title }</h1>
                <p>{desc}</p>
                {btnText !== "Learn More" ? 
                    <Button>{btnText}</Button>
                :
                    <Button component={Link} to={`/services/${pagename}`}>{btnText}</Button>
                }
                
            </div>
        </div>
    )
}
Card.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    imgSrc: PropTypes.string,
    pagename: PropTypes.string
}
Card.defaultProps = {
    imgSrc: imgPlaceholder,
    pagename: "",
    btnText: "Learn More"
}

export default Card
