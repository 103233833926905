import React from 'react'
import PageHeader from '../../layout/PageHeader';
import '../../../styles/pages/ServicePage.scss'
import ContactForm from '../../layout/ContactForm'
import contactInfo from '../../../globalInfo/contactInfo.json'
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import $ from 'jquery';
//thumbs
import pavers from '../../../assests/images/service-thumbs/pavers/service-thumb-pavers-patio.jpg';

import concrete from '../../../assests/images/service-thumbs/concrete/service-thumb-concrete-patio.jpg'
function Patios() {
    let scrollToContact = (e) => {
        //e.preventDefalut();
        $('html,body').animate({
          scrollTop: $("#contact").offset().top
        });
        $("input#first_name").focus();
    }
    $(".learnLink").on('click', function (e) {  
        scrollToContact(e)
    })
    return (
        
        <div id="main">
            <PageHeader title="Patio Work" desc="We install and renovate pavers, asphalt, facades, concrete, and more. This includes patios, steps, poolsides, foundations, and driveways!"></PageHeader>
            <section class="wrapper intro">
                <p>A patio is an essential element in today's backyards. Concrete is the most popular patio material in the country. With many decorative finishes, concrete patios attract homeowners who want an outdoor surface with unlimited design potential. When compared to wood decks, pavers and natural stone, a concrete patio is also more durable and requires less maintenance.</p>
                <p>There are a variety of concrete services available for residential and commercial construction. Get more information about each of these types of concrete work:</p>
                <p><strong>Ready to start your project?</strong> <button class="learnLink" onClick={ scrollToContact }>Contact Us</button> for a free quote and consultation.</p>
            </section>
          
            <section class="project-listings">
                <div class="wrapper">
                    
                    <div class="project-list">
                        <div class="project-list-left">
                            <img alt="Concrete Steps" src={concrete} />
                        </div>
                        <div class="project-list-right">
                            <h3>Poured Concrete Patio</h3>
                            <p>Choosing a plain concrete patio will most definitely save you money when compared to pavers. However, as you add upgrades, like stamping and coloring, the prices become more comparable.</p>
                            <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                        </div>
                    </div> 
                    <div class="project-list">
                        <div class="project-list-left">
                        <img alt="Patio Pavers" src={pavers} />
                        </div>
                        <div class="project-list-right">
                            <h3>Patio Pavers</h3>
                            <p>Pavers are usually more visually appealing than concrete slabs 1 because you can manipulate the pavers and create designs, and they come in a variety of colors. </p>
                            <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                        </div>
                    </div> 
                </div>
            </section>
            <section id="contact" class="section-green">
                <h2 style={{ color: "white", textAlign: 'center', fontSize: '33px', fontWeight:400}}>Request More Info</h2>
                    <ContactForm></ContactForm>
                <h2 style={{ color: "white", textAlign: 'center', marginTop: '30px', fontSize: '27px', fontWeight: 400 }}>Or call <a style={{color:"white", textDecoration:"none", fontWeight: 500}} href={"tel:"+contactInfo.phone}>{ contactInfo.phone }</a></h2>
            </section>
        </div>                          
    )
}

export default Patios
