import React from 'react';
import { Link } from 'react-router-dom';
import SocialLinks from "./SocialLinks";
import BlogSubscriptionForm from "./BlogSubscriptionForm"
import Logo from '../../assests/images/ogc-construction/svg/ogc-construction-black.svg';
import '../../styles/Footer.scss';
import clientInfo from '../../globalInfo/contactInfo.json';

const today = new Date();

export const Footer = () => {
    return (
        <footer>
            <div class="wrapper">
            <div class="cols">
                <div id="footer__menu">
                    <div class="col">
                        <h4>Services</h4>
                        <ul>
                            <li><Link exact to="">Pavers</Link></li>
                            <li><Link exact to="">Concrete</Link></li>
                            <li><Link exact to="">Asphalt</Link></li>
                            <li><Link exact to="">Foundations</Link></li>
                        </ul>
                    </div> 
                    <div class="col">
                        <h4>About</h4>
                            <ul>
                                <li><Link exact to="">About Us</Link></li>
                                <li><Link exact to="">Service Area</Link></li>
                                <li><Link exact to="">Partners</Link></li>
                                <li><Link exact to="">Contact Us</Link></li>
                            </ul>
                    </div> 
                    <div class="col"></div>
                    <div class="col"></div>
                </div>
                
                
                <div id="engagement">
                    <BlogSubscriptionForm></BlogSubscriptionForm>
                        <SocialLinks solidColor={"#a2a2a2bf"}></SocialLinks>
                </div> 
            </div>
            </div>
            
            <div id="tertiary">
                <div class="wrapper">
                    <div>
                        <img alt="OGC Logo" id="footer__logo" src={ Logo }/>
                    </div>
                    <ul>
                        <li><Link exact to="/policy/terms-of-service">Terms of Service</Link></li>
                        <li><Link exact to="/policy/privacy">Privacy</Link></li>
                        <li><Link exact to="/policy/cookies">Cookies</Link></li>
                        <li id="trademark">© {today.getFullYear()} { clientInfo.company_name } LLC.</li>
                    </ul>
                    
                </div>
                <div id="trademarkMobile">© {today.getFullYear()} { clientInfo.company_name } LLC.</div>
            </div>
	    </footer>
    )
}


