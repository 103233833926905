import React from 'react'
import PageHeader from '../../layout/PageHeader';
import '../../../styles/pages/ServicePage.scss'
import ContactForm from '../../layout/ContactForm'
import contactInfo from '../../../globalInfo/contactInfo.json'
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import $ from 'jquery';
//thumbs
import steps from '../../../assests/images/service-thumbs/concrete/service-thumb-concrete-steps.jpg';
import walkway from '../../../assests/images/service-thumbs/concrete/service-thumb-concrete-walkway.jpg';
import deck from '../../../assests/images/service-thumbs/concrete/service-thumb-pool-deck.jpg';
const Concrete = () => {
    let scrollToContact = (e) => {
        //e.preventDefalut();
        $('html,body').animate({
          scrollTop: $("#contact").offset().top
        });
        $("input#first_name").focus();
    }
    $(".learnLink").on('click', function (e) {  
        scrollToContact(e)
    })
    return (
        
        <div id="main">
            <PageHeader title="Concrete Work" desc="We install and renovate pavers, asphalt, facades, concrete, and more. This includes patios, steps, poolsides, foundations, and driveways!"></PageHeader>
            <section class="wrapper intro">
                <p>When it comes to concrete work, there are a lot of options. Whether you need new concrete poured or old concrete repaired, it is best to do your research before starting your project. Options range from a plain gray surface, to beautiful decorative concrete finishes with pattern and texture.</p>
                <p>There are a variety of concrete services available for residential and commercial construction. Get more information about each of these types of concrete work:</p>
                <p><strong>Ready to start your project?</strong> <button class="learnLink" onClick={ scrollToContact }>Contact Us</button> for a free quote and consultation.</p>
            </section>
          
            <section class="project-listings">
            <div class="wrapper">
                <div class="project-list">
                    <div class="project-list-left">
                        <img alt="Stamped Concrete Walkway" src="https://static.concretenetwork.com/photo-gallery/images/212x159Exact/concrete-walkways_10/fan-pattern-brick-brickform_553.jpg" />
                    </div>
                    <div class="project-list-right">
                        <h3>Stamped Concrete</h3>
                        <p>By stamping freshly placed concrete, you can achieve the high-end look of stone or brick pavement—often at a lower cost.</p>
                        <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                    </div>
                </div> 
                <div class="project-list">
                    <div class="project-list-left">
                        <img alt="Concrete Steps" src={steps} />
                    </div>
                    <div class="project-list-right">
                        <h3>Steps</h3>
                        <p>Concrete steps eventually break up, especially in northern climates. Water soaks into the concrete, freezes and breaks off the outside corners. Cutting away the damaged concrete and pouring a new concrete cap on the step can often repair badly broken concrete steps. The fix will be durable and you avoid the high expense of a complete tear-out.</p>
                        <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                    </div>
                </div> 
                <div class="project-list">
                    <div class="project-list-left">
                        <img alt="Concrete Sidewalk" src={walkway} />
                    </div>
                    <div class="project-list-right">
                        <h3>Walkways</h3>
                        <p>While plain gray concrete is still the most often surface installed, as seen on most concrete sidewalks, there are numerous decorative concrete alternatives sure to dress it up, taking your sidewalk from boring to amazing.</p>
                        <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                    </div>
                </div> 
                <div class="project-list">
                    <div class="project-list-left">
                        <img alt="Concrete Pool Deck" src={deck} />
                    </div>
                    <div class="project-list-right">
                        <h3>Pool Decks</h3>
                        <p>The area that surrounds your swimming pool is called a pool deck. For in-ground pools, many people choose a concrete pool deck, since it’s a durable material that can be customized. Wood and composite decking are used around above-ground pools.</p>
                        <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                    </div>
                </div> 
                </div>
            </section>
            <section id="contact" class="section-green">
                <h2 style={{ color: "white", textAlign: 'center', fontSize: '33px', fontWeight:400}}>Request More Info</h2>
                    <ContactForm></ContactForm>
                <h2 style={{ color: "white", textAlign: 'center', marginTop: '30px', fontSize: '27px', fontWeight: 400 }}>Or call <a style={{color:"white", textDecoration:"none", fontWeight: 500}} href={"tel:"+contactInfo.phone}>{ contactInfo.phone }</a></h2>
            </section>
        </div>                          
    )
}
export default Concrete

