import React, { useState } from 'react';
import axios from 'axios';
import $ from "jquery";
import "../../styles/BlogSubscriptionForm.scss"
import CheckIcon from '@material-ui/icons/Check';
import Spinner from '../layout/Spinner';
import SendIcon from '@material-ui/icons/Send';
export default function BlogSubscriptionForm() {
    
    
    

    let [emailInfo, updateInfo] = useState({
        email: "",
        submitted:false,
        error:false
    });

    let onChange = (e) => {
        updateInfo({
            ...emailInfo,
            [e.target.name]: e.target.value
        })
    }

    // const recaptchaRef = React.useRef();
 
    // const onSubmitWithReCAPTCHA = async () => {
    //     const token = await recaptchaRef.current.executeAsync();
    
    //     // apply to form data
    // }

    const onSubmit = async function (e) {
        e.preventDefault();
        updateInfo({
            ...emailInfo,
            submitted: "pending"
            
        });
       
        //emailInfo.date_time = moment().format("ddd, MMM D YYYY, h:mm A");
        const options = {
            url: 'https://ogc-construction.herokuapp.com/forms/blog-subscribe',
            method: 'POST',
            //headers: { "content-type": "application/x-www-form-urlencoded",},
            data: emailInfo
        };
        try {
            let res = await axios(options)
            if (res.status === 200) {
                //change form state to "submitted"
                //save state
                updateInfo({
                    email:"",
                    submitted: true,
                    error: false
                });
            } else {
                //backend sends notification email to kenny
                //change form state to "error"
                updateInfo({
                    ...emailInfo,
                    submitted: true,
                    error: true
                })
            }
        } catch (error) {
            console.log(error);
            updateInfo({
                ...emailInfo,
                submitted: false,
                error: true
            });
            $('html,body').animate({
                scrollTop: $("footer").offset().top
            });
        }
        
    }
    
    return (
        <div>
            <h3 style={{display: 'flex', alignItems: 'center'}}><SendIcon style={{marginRight: '7px'}}></SendIcon> Subscribe to our monthly blog article</h3>
            <p>Your email address:</p>
            {emailInfo.submitted === true ? (
                <form id="blog-subscription">
                    <div className="blur submitted"></div>
                    <input type="email" value={emailInfo.email} name="email" placeholder="Check your email now to confirm your sign-up." disabled/>
                    <CheckIcon></CheckIcon>
                </form>
            ): (
                    <form id="blog-subscription" onSubmit={onSubmit}>
                        <div className="blur"></div>
                        <input type="email" value={emailInfo.email} name="email" onChange={onChange} />
                        {emailInfo.submitted === "pending" ? (
                            <Spinner></Spinner>
                        ): (
                            <input type="submit" value="GO" name="commit"/>
                        )}
                    
                </form>
            )}
            
           
        </div>
    )
}
