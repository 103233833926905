import React from 'react'
import PageHeader from '../../layout/PageHeader';
import '../../../styles/pages/ServicePage.scss'
import ContactForm from '../../layout/ContactForm'
import contactInfo from '../../../globalInfo/contactInfo.json'
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import $ from 'jquery';
//thumbs
import pavers from '../../../assests/images/service-thumbs/pavers/service-thumb-pavers-pool-deck.jpg';
import concrete from '../../../assests/images/service-thumbs/concrete/service-thumb-pool-deck.jpg'
function PoolDecks() {
    let scrollToContact = (e) => {
        //e.preventDefalut();
        $('html,body').animate({
          scrollTop: $("#contact").offset().top
        });
        $("input#first_name").focus();
    }
    $(".learnLink").on('click', function (e) {  
        scrollToContact(e)
    })
    return (
        
        <div id="main">
            <PageHeader title="Pool Decks" desc="We install and renovate pavers, asphalt, facades, concrete, and more. This includes patios, steps, poolsides, foundations, and driveways!"></PageHeader>
            <section class="wrapper intro">
                <p>The area that surrounds your swimming pool is called a pool deck. For in-ground pools, many people choose a concrete or paver pool deck, since it’s a durable material that can be customized. Wood and composite decking are used around above-ground pools.One of the biggest trends in outdoor design today is installing a decorative concrete pool deck: a colored, textured, and inviting area surrounding the pool that provides a safe, slip-resistant deck for sunbathing and barbecuing.</p>
                <p>Pavers are a great option for pool decks because they are durable; chlorine and salt resistant, and can provide the look of natural stone. Pavers also provide good traction and are comfortable underfoot, because the joints take on moisture that helps cool the surface.</p>
                <p><strong>Ready to start your project?</strong> <button class="learnLink" onClick={ scrollToContact }>Contact Us</button> for a free quote and consultation.</p>
            </section>
          
            <section class="project-listings">
            <div class="wrapper">
                <div class="project-list">
                        <div class="project-list-left">
                            <img alt="Concrete Pool Deck" src={concrete} />
                        </div>
                        <div class="project-list-right">
                            <h3>Concrete Pool Decks</h3>
                            <p>Decorative concrete has opened the doors to creating pool decks that complement the exterior of the home, meld with the outdoor environment, and replicate traditionally more expensive materials such as slate, stone, or even wood. Inground swimming pool decks are given nearly as much attention as the interior design of the home.</p>
                            <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                        </div>
                    </div> 
                    <div class="project-list">
                        <div class="project-list-left">
                            <img alt="Pool Deck Pavers" src={pavers} />
                        </div>
                        <div class="project-list-right">
                            <h3>Pool Deck Pavers</h3>
                            <p>The advantage of using pavers for pool decks is that they can be spot replaced, if necessary. They can also be removed and replaced for electrical or plumbing repairs under the surface. Also, if you want to add on to your pool deck at a later time, it’s easy to install matching pavers and create a seamless transition.</p>
                            <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                        </div>
                    </div> 
                </div>
            </section>
            <section id="contact" class="section-green">
                <h2 style={{ color: "white", textAlign: 'center', fontSize: '33px', fontWeight:400}}>Request More Info</h2>
                    <ContactForm></ContactForm>
                <h2 style={{ color: "white", textAlign: 'center', marginTop: '30px', fontSize: '27px', fontWeight: 400 }}>Or call <a style={{color:"white", textDecoration:"none", fontWeight: 500}} href={"tel:"+contactInfo.phone}>{ contactInfo.phone }</a></h2>
            </section>
        </div>                          
    )
}

export default PoolDecks
