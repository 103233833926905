import React from 'react';
import {Link} from 'react-router-dom'
import PageHeader from '../../layout/PageHeader';
import '../../../styles/pages/ServicePage.scss'
import ContactForm from '../../layout/ContactForm'
import contactInfo from '../../../globalInfo/contactInfo.json'
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import $ from 'jquery';
//thumbs
import driveway from '../../../assests/images/service-thumbs/pavers/service-thumb-pavers-driveway.jpg';
import patio from '../../../assests/images/service-thumbs/pavers/service-thumb-pavers-patio.jpg';
import pool from '../../../assests/images/service-thumbs/pavers/service-thumb-pavers-pool-deck.jpg';
import walkway from '../../../assests/images/service-thumbs/pavers/service-thumb-pavers-walkway.jpg';
function Pavers() {
    let scrollToContact = (e) => {
        //e.preventDefalut();
        $('html,body').animate({
          scrollTop: $("#contact").offset().top
        });
        $("input#first_name").focus();
    }
    $(".learnLink").on('click', function (e) {  
        scrollToContact(e)
    })
    
    return (
        
        <div id="main">
            <PageHeader title="Pavers and Brick" desc="We install and renovate pavers, asphalt, facades, concrete, and more. This includes patios, steps, poolsides, foundations, and driveways!"></PageHeader>
            <section class="wrapper intro">
                <p>Concrete pavers, often referred to as paving stones, are a popular outdoor flooring option. Similar to tiles, pavers are manufactured in specific shapes, sizes and colors and arrive on site ready to be installed.</p>
                <p>Pavers are most commonly installed over a compacted base of soil and sand. Because there isn’t any grout between the joints, concrete pavers offer a permeable surface that allows water to pass through. However, this also means that they may shift over time and that weeds could grow between the stones. Hiring a professional paver contractor to install your pavers, rather than laying them yourself, is the best way to protect your patio, driveway or sidewalk from these concerns.</p>
                <p><strong>Ready to start your project?</strong> <button class="learnLink" onClick={ scrollToContact }>Contact Us</button> for a free quote and consultation.</p>
            </section>
          
            <section class="project-listings">
            <div class="wrapper">
                <div class="project-list">
                    <div class="project-list-left">
                        <img alt="Patio Pavers" src={patio} />
                    </div>
                    <div class="project-list-right">
                        <h3>Patio Pavers</h3>
                        <p>When compared to wood decks, concrete paver patios offer a number of distinct advantages. They aren’t vulnerable to wood rot or splintering, they don’t require regular maintenance with wood stains and sealers, and they conserve resources by saving lumber. Concrete pavers can also be configured into any shape to accommodate backyard space restrictions.</p>
                        <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                    </div>
                </div> 
                <div class="project-list">
                    <div class="project-list-left">
                        <img alt="Driveway Pavers" src={driveway} />
                    </div>
                    <div class="project-list-right">
                        <h3>Driveways</h3>
                        <p>Concrete pavers are one of your best choices for a new or replacement driveway if your goals are design versatility, durability and easy maintenance. They are comparable to <Link to="/services/asphalt">asphalt</Link> in terms of durability, with the added advantage of easy, seamless replacement if repairs are ever necessary. A paver driveway can also be driven on immediately after installation, unlike poured-in-place concrete.</p>
                        <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                    </div>
                </div> 
                <div class="project-list">
                    <div class="project-list-left">
                        <img alt="Sidewalk" src={walkway} />
                    </div>
                    <div class="project-list-right">
                        <h3>Walkways</h3>
                        <p>Pavers give you the versatility to form walkways with gentle curved configurations and make them any width you desire. They also allow you to replicate the traditional look of a natural stone or brick walkway, but come in a wider array of colors and shapes.</p>
                        <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                    </div>
                </div> 
                <div class="project-list">
                    <div class="project-list-left">
                            <img alt="Pool Deck" src={pool}/>
                    </div>
                    <div class="project-list-right">
                        <h3>Pool Decks</h3>
                        <p>Concrete pavers are a great option for pool decks because they are durable; chlorine and salt resistant, and can provide the look of natural stone. Pavers also provide good traction and are comfortable underfoot, because the joints take on moisture that helps cool the surface.</p>
                        <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                    </div>
                </div> 
                </div>
            </section>
            <section id="contact" class="section-green">
                <h2 style={{ color: "white", textAlign: 'center', fontSize: '33px', fontWeight:400}}>Request More Info</h2>
                    <ContactForm></ContactForm>
                <h2 style={{ color: "white", textAlign: 'center', marginTop: '30px', fontSize: '27px', fontWeight: 400 }}>Or call <a style={{color:"white", textDecoration:"none", fontWeight: 500}} href={"tel:"+contactInfo.phone}>{ contactInfo.phone }</a></h2>
            </section>
        </div>                          
    )
}

export default Pavers
