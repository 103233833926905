import React from 'react'
import PageHeader from '../../layout/PageHeader';
import '../../../styles/pages/ServicePage.scss'
import ContactForm from '../../layout/ContactForm'
import contactInfo from '../../../globalInfo/contactInfo.json'
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import $ from 'jquery';
//thumbs
import concrete from '../../../assests/images/service-thumbs/driveways/service-thumb-concrete-driveway.jpg';
import asphalt from '../../../assests/images/service-thumbs/asphalt/service-thumb-asphalt-driveway.jpg';
import pavers from '../../../assests/images/service-thumbs/pavers/service-thumb-pavers-driveway.jpg'
function Driveways() {
    let scrollToContact = (e) => {
        //e.preventDefalut();
        $('html,body').animate({
          scrollTop: $("#contact").offset().top
        });
        $("input#first_name").focus();
    }
    $(".learnLink").on('click', function (e) {  
        scrollToContact(e)
    })
    return (
        
        <div id="main">
            <PageHeader title="Driveways" desc="We install and renovate pavers, asphalt, facades, concrete, and more. This includes patios, steps, poolsides, foundations, and driveways!"></PageHeader>
            <section class="wrapper intro">
                <p>Not long ago, deciding on a driveway material was easy, usually asphalt, maybe poured concrete for warmer climates.Today, pavers have introduced unlimited color, design, and customizations options.</p>
                <p>There are a variety of concrete services available for residential and commercial construction. Get more information about each of these types of concrete work:</p>
                <p><strong>Ready to start your project?</strong> <button class="learnLink" onClick={ scrollToContact }>Contact Us</button> for a free quote and consultation.</p>
            </section>
          
            <section class="project-listings">
            <div class="wrapper">
                <div class="project-list">
                    <div class="project-list-left">
                        <img alt="Stamped Concrete Walkway" src={concrete} />
                    </div>
                    <div class="project-list-right">
                        <h3>Concrete Driveway</h3>
                        <p>By stamping freshly placed concrete, you can achieve the high-end look of stone or brick pavement—often at a lower cost.</p>
                        <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                    </div>
                </div>
                <div class="project-list">
                    <div class="project-list-left">
                        <img alt="Asphalt Driveway" src={asphalt} />
                    </div>
                    <div class="project-list-right">
                        <h3>Asphalt Driveways</h3>
                        <p>Climate is a major consideration when choosing between asphalt and concrete. It takes snow and ice longer to melt on concrete than asphalt. While asphalt is easier to repair than concrete, asphalt needs it more frequently.</p>
                        <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                    </div>
                </div>     
                <div class="project-list">
                    <div class="project-list-left">
                        <img alt="Driveway with Pavers" src={pavers} />
                    </div>
                    <div class="project-list-right">
                        <h3>Pavers for Driveways</h3>
                        <p>Although plain gray concrete is still installed most often, more people are catching on to the dazzling effects possible with decorative concrete—and seeing the instant curb appeal a decorative driveway can give to any home, no matter what the style. If you already have a concrete driveway, you can still give it a decorative makeover. We offer many products and methods that can rejuvenate or resurface existing plain-gray driveways.</p>
                        <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                    </div>
                </div> 
                
                </div>
            </section>
            <section id="contact" class="section-green">
                <h2 style={{ color: "white", textAlign: 'center', fontSize: '33px', fontWeight:400}}>Request More Info</h2>
                    <ContactForm></ContactForm>
                <h2 style={{ color: "white", textAlign: 'center', marginTop: '30px', fontSize: '27px', fontWeight: 400 }}>Or call <a style={{color:"white", textDecoration:"none", fontWeight: 500}} href={"tel:"+contactInfo.phone}>{ contactInfo.phone }</a></h2>
            </section>
        </div>                          
    )
}

export default Driveways
