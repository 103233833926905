import React from 'react'
import PageHeader from '../../layout/PageHeader';
import '../../../styles/pages/ServicePage.scss'
import ContactForm from '../../layout/ContactForm'
import contactInfo from '../../../globalInfo/contactInfo.json'
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import $ from 'jquery';
//thumbs
import concrete from '../../../assests/images/service-thumbs/walls/service-thumb-concrete-walls.jpg';
import segmental from '../../../assests/images/service-thumbs/walls/service-thumb-block-retaining-wall.jpg'
function Walls() {
    let scrollToContact = (e) => {
        //e.preventDefalut();
        $('html,body').animate({
          scrollTop: $("#contact").offset().top
        });
        $("input#first_name").focus();
    }
    $(".learnLink").on('click', function (e) {  
        scrollToContact(e)
    })
    return (
        
        <div id="main">
            <PageHeader title="Retaining Walls" desc="We install and renovate pavers, asphalt, facades, concrete, and more. This includes patios, steps, poolsides, foundations, and driveways!"></PageHeader>
            <section class="wrapper intro">
                <p>Retaining walls provide lateral support to vertical slopes of soil. They retain soil which would otherwise collapse into a more natural shape. The retained soil is sometimes referred to as backfill.</p>
                <p>Retaining walls can be constructed with a variety of building techniques and with different materials such as steel, timber, or reinforced soil, but most frequesntly concrete.</p>
                <p><strong>Ready to start your project?</strong> <button class="learnLink" onClick={ scrollToContact }>Contact Us</button> for a free quote and consultation.</p>
            </section>
          
            <section class="project-listings">
                <div class="wrapper">
                    <div class="project-list">
                        <div class="project-list-left">
                            <img alt="Concrete Walls" src={concrete} />
                        </div>
                        <div class="project-list-right">
                            <h3>Concrete Walls</h3>
                            <p>Concrete can be used to pour new walls, or existing walls can be covered with concrete to give them a new look. Other building methods include concrete block construction or precast concrete wall panels.</p>
                            <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                        </div>
                    </div> 
                    <div class="project-list">
                        <div class="project-list-left">
                            <img alt="Concrete Steps" src={segmental} />
                        </div>
                        <div class="project-list-right">
                            <h3>Segmental retaining walls</h3>
                            <p>Segmental retaining walls consist of modular concrete blocks that interlock with each other. They are used to hold back a sloping face of soil to provide a solid, vertical front, thus transforming steep slopes into flat, usuable surfaces.</p>
                            <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                        </div>
                    </div> 
                    
                </div>
            </section>
            <section id="contact" class="section-green">
                <h2 style={{ color: "white", textAlign: 'center', fontSize: '33px', fontWeight:400}}>Request More Info</h2>
                    <ContactForm></ContactForm>
                <h2 style={{ color: "white", textAlign: 'center', marginTop: '30px', fontSize: '27px', fontWeight: 400 }}>Or call <a style={{color:"white", textDecoration:"none", fontWeight: 500}} href={"tel:"+contactInfo.phone}>{ contactInfo.phone }</a></h2>
            </section>
        </div>                          
    )
}

export default Walls
