import React from 'react';
import PageHeader from '../layout/PageHeader';
import { Link } from 'react-router-dom';
import '../../styles/pages/NotFound.scss';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LogoAnimation from '../images/LogoAnimation'
//http status 404 - Not Found
export const NotFound = () => {
    return (
        <div id="main" className="NotFound">
            <PageHeader title="404 Page Not Found" desc="This page is not availble." display="none"></PageHeader>
            <div class="center container">
                <Link to="/"><LogoAnimation style={{marginBottom: '27px'}}/></Link>
                
                <ul>
                    <li>The page has been deleted</li>
                    <li>You clicked a broken link</li>
                    <li>The URL contains a typo</li>
                </ul>
                <Link className="button normal" to="/" style={{ textAlign: "center", display: "block" }}><span ><ChevronLeftIcon/>Back home</span></Link>
            </div>
        </div>
    )
}