import React from "react";
import { useHistory } from "react-router-dom"
// JSX
import HeroSlider, { Slide, Nav} from "hero-slider";
import Button from '@material-ui/core/Button'
import SocialLinks from '../layout/SocialLinks'
import "../../styles/Hero.scss"
import $ from 'jquery'
// background images
import patio1 from '../../assests/images/backgrounds/ogc-hero-stock-patio-dark.jpg';
import patio2 from '../../assests/images/backgrounds/ogc-hero-stock-patio2-dark.jpg';
import steps from '../../assests/images/backgrounds/ogc-hero-stock-steps-dark.jpg';



const Hero = () => {
  const history = useHistory();

  function changePage(route) {
    history.push(route);
  }
  let scrollToContact = () => {
    
    $('html,body').animate({
      scrollTop: $("#contact").offset().top
    });
    $("input#first_name").focus();
  } 
  
  return (
    <HeroSlider
      slidingAnimation="left_to_right"
      orientation="horizontal"
      initialSlide={1}
      onBeforeChange={(previousSlide, nextSlide) => {
        //console.log("onBeforeChange", previousSlide, nextSlide)
      }}
      onChange={nextSlide => {
        //console.log("onChange", nextSlide)
      }}
      onAfterChange={nextSlide => {
        //console.log("onAfterChange", nextSlide)
      }}
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.33)"
      }}
      settings={{
        slidingDuration: 1000,
        slidingDelay: 100,
        shouldAutoplay: true,
        shouldDisplayButtons: false,
        autoplayDuration: 5000,
        height: "500px"
      }}
    >
        {/* <OverlayContainer>
            <div class="overlay">test</div>
        
      </OverlayContainer>  */}

      <Slide
        background={{
          backgroundImage: patio1,
          backgroundAttachment: "fixed"
        }}
      >
       
         
          <div class="sliderContentWrapper">
            <h1>Get a free quote today</h1>
            <p>We even provide quotes on weekends and evenings to fit your schedule.</p>
            <Button onClick={ scrollToContact }>Request Quote</Button>
          </div>
        
      </Slide>

      <Slide
        background={{
          backgroundImage: patio2,
          backgroundAttachment: "fixed"
        }}
      >
        <div class="sliderContentWrapper">
            <h1>Need inspiration?</h1>
            <p>Visit our social media to see our past projects. You can also send us direct messages for a any quick questions.</p>
          {/* <Button>Request Quote</Button> */}
          <SocialLinks originalColor={true}></SocialLinks>
          </div>
        </Slide>

      <Slide
        background={{
          backgroundImage: steps,
          backgroundAttachment: "fixed"
        }}
      >
        <div class="sliderContentWrapper">
            <h1>Serving the greater Central Jersey area since 2011</h1>
            <p>We've completed projects across NJ, Philly, and NYC. Need help with a project in your area?</p>
          <Button onClick={scrollToContact}>Learn More</Button>
          </div>
      </Slide>

      {/* <Slide
        background={{
          backgroundImage: craterRock,
          backgroundAttachment: "fixed"
        }}
      >
        <div class="sliderContentWrapper">
            <h1>Slide 4</h1>
            <p>This is a long description for slide 1</p>
            <Button>Request Quote</Button>
          </div>
      </Slide> */}

      <Nav />
    </HeroSlider>
  );
};

export default Hero;
