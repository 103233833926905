import React from 'react'
import PageHeader from '../../layout/PageHeader';
import '../../../styles/pages/ServicePage.scss'
import ContactForm from '../../layout/ContactForm'
import contactInfo from '../../../globalInfo/contactInfo.json'
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import $ from 'jquery';
//thumbs
import pit from '../../../assests/images/service-thumbs/firepits/service-thumb-paver-fire-pit.jpg';
import place from '../../../assests/images/service-thumbs/firepits/service-thumb-precast-fireplace.jpg'
function Firepits() {
    let scrollToContact = (e) => {
        //e.preventDefalut();
        $('html,body').animate({
          scrollTop: $("#contact").offset().top
        });
        $("input#first_name").focus();
    }
    $(".learnLink").on('click', function (e) {  
        scrollToContact(e)
    })
    return (
        
        <div id="main">
            <PageHeader title="Fire Pits and Fireplaces" desc="We install and renovate pavers, asphalt, facades, concrete, and more. This includes patios, steps, poolsides, foundations, and driveways!"></PageHeader>
            <section class="wrapper intro">
                <p>Outdoor fireplaces continue to grow in popularity with the outdoor room boom fueling the fire. Magazines and web-publishers frequently post beautiful photos of large stone fireplaces and homeowners are now demanding fire places, not just fire pits in their outdoor living rooms.</p>
                <p>Fire pits are a great way to enjoy the view. If you have a lot with a view, locate fire features at the edge of the property in a place where people will have a chance to enjoy the fire while taking in the surroundings.</p>
                <p>While there are many <a rel="noreferrer" target="_blank" href="https://www.homedepot.com/c/ah/how-to-build-a-fire-pit/9ba683603be9fa5395fab909c7f8239">do-it-yourself articles</a> for building your very own firepit online, we highly suggest hiring projessionals to ensure your fire pit or fireplace remains in great condition for a longer period of time. </p>
                <p><strong>Ready to start your project?</strong> <button class="learnLink" onClick={ scrollToContact }>Contact Us</button> for a free quote and consultation.</p>
            </section>
          
            <section class="project-listings">
                <div class="wrapper">
                    <div class="project-list">
                        <div class="project-list-left">
                            <img alt="Pre-Cast Concrete" src={pit} />
                        </div>
                        <div class="project-list-right">
                            <h3>Pre-Cast Concrete</h3>
                            <p> These features are easy to assemble and because of their portable design, usually do not require permits or structural engineering. While it is often ok to burn small amounts of wood in these features, with the addition of a spark arrestor, most manufacturers advise against it.</p>
                            <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                        </div>
                    </div> 
                    <div class="project-list">
                        <div class="project-list-left">
                            <img alt="Concrete Steps" src={place} />
                        </div>
                        <div class="project-list-right">
                            <h3>Brick or Cinderblock Fire Pits</h3>
                            <p>Typical construction on all of these features involves digging a pit, raising walls with brick or cinderblock, and veneering the outside with stucco, stone, brick, or tile. The interior veneer must be authentic firebrick with a fire-proof grout.</p>
                            <button class="learnLink" onClick={ scrollToContact }>Learn More<ChevronRightIcon /></button>
                        </div>
                    </div> 
                    
                </div>
            </section>
            <section id="contact" class="section-green">
                <h2 style={{ color: "white", textAlign: 'center', fontSize: '33px', fontWeight:400}}>Request More Info</h2>
                    <ContactForm></ContactForm>
                <h2 style={{ color: "white", textAlign: 'center', marginTop: '30px', fontSize: '27px', fontWeight: 400 }}>Or call <a style={{color:"white", textDecoration:"none", fontWeight: 500}} href={"tel:"+contactInfo.phone}>{ contactInfo.phone }</a></h2>
            </section>
        </div>                          
    )
}

export default Firepits
