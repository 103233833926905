import React from 'react';
import Hero from '../layout/Hero';
import '../../styles/pages/Home.scss';
import Card from '../layout/Card';
import ContactForm from '../layout/ContactForm'
import { Link } from 'react-router-dom'
import PageHeader from '../layout/PageHeader';
//service images
import firepit from "../../assests/images/projects/stock/small/fire-pit.jpg";
import driveway from "../../assests/images/projects/stock/small/driveway-concrete.jpg";
import steps from "../../assests/images/projects/stock/small/pavers-steps-nice.jpg";
import walls from "../../assests/images/projects/stock/small/retainingwall.jpg";
import ramps from "../../assests/images/projects/stock/small/concrete-ramp.jpg";
import facades from "../../assests/images/projects/stock/small/pavers-facade.jpg";
import snowRemoval from "../../assests/images/projects/stock/small/snowremoval.jpg";
import asphalt from "../../assests/images/projects/stock/small/asphalt-basketballcourt.jpg";
import contactInfo from '../../globalInfo/contactInfo.json'

import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export const Home = (props) => {
    return (
        <div id="main">
            <PageHeader display="none"></PageHeader>
            <section id="hero">
                <Hero/>
            </section>
            <section id="about">
                <div className="wrapper">
                    <div class="middle">
                        <p>With over 10 years of experience providing quality services in the greater New Jersey, NYC, and Philadelphia areas, at OGC Construction, we pride ourselves on reliability, communication, and quality work. </p>

                        <p>We strongly believe in giving our absolute best in all of our projects no matter how big or small. We are experts in our trade, and will always strive for customer satisfaction. Have a question regarding a future installation? Reach out to a representative here.</p>
                    </div>
                

                </div>
                
            </section>
            <section id="services" >
                <div className="wrapper">
                    <h1 class="text-centered">Featured services</h1>
                    <div>
                        <div class="serviceRow">
                            <Card title="Fire Pits" pagename="firepits" desc="" imgSrc={ firepit }></Card>
                            <Card title="Driveway" pagename="driveways" desc="" imgSrc={ driveway }></Card>
                            <Card title="Steps" pagename="steps" desc="" imgSrc={ steps }></Card>
                            <Card title="Retaining Walls" pagename="walls" desc="" imgSrc={ walls }></Card>
                            
                        </div>
                        <div class="serviceRow">
                            <Card title="Ramps" pagename="steps" desc="" imgSrc={ramps}></Card>
                            <Card title="Facades" pagename="facades" desc="" imgSrc={facades}></Card>
                            <Card title="Asphalt" pagename="asphalt" desc="" imgSrc={asphalt}></Card>
                            <Card title="Snow Removal" pagename="" desc="" imgSrc={snowRemoval} btnText={"Winter 2021"}></Card>
                        </div>
                        
                    </div>
                    <Button component={Link} to={`/services`} className="servicesLink">All Services <ArrowForwardIosIcon/></Button>
                </div>
            </section>
            {/* <section id="portfolio">

            </section> */}
            <section id="contact" class="section-green">
                <h2 style={{ color: "white", textAlign: 'center', fontSize: '33px', fontWeight:400}}>Request More Info</h2>
                    <ContactForm></ContactForm>
                <h2 style={{ color: "white", textAlign: 'center', marginTop: '30px', fontSize: '27px', fontWeight: 400 }}>Or call <a style={{color:"white", textDecoration:"none", fontWeight: 500}} href={"tel:"+contactInfo.phone}>{ contactInfo.phone }</a></h2>
            </section>
        </div>
    )
}