// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import Microstory from '../layout/Microstory'
// Import Swiper styles
import 'swiper/swiper.scss';
//import 'swiper/components/navigation/navigation.scss';
import '../../styles/module_overrides/swiper/navigation.scss'; //custom navigation
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import '../../styles/Slider.scss'
import placeholer from '../../assests/images/image-not-found.png'
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const tstyle = {
  textAlign: 'center',
  padding: '0px',
  margin: '0px',
  color: 'white',
  fontSize: '21px',
  fontWeight: '700',
  textTransform: 'uppercase',
  display: 'block',
  marginBottom: '-45px',
  paddingTop: '23px',
  letterSpacing: '3px',
  opacity: '.9'
}

const Slider = () => {
  return (
    <div id="Slider">
      <h2 style={tstyle}>Testimonials</h2>
    <Swiper
      spaceBetween={10}
      slidesPerView={'auto'}
          navigation
          centeredSlides={true}
      pagination={{ clickable: true }}
      //scrollbar={{ draggable: true }}
        onSwiper={(swiper) => {
            
        }}
        onSlideChange={() => { 

        }}
          loop={true}
       
      >
    {/* <div class="swiper-group">
      <a href="#" class="swiper-button-next" tabindex="0" role="button" aria-label="Previous slide"><span>Previous</span></a>
      <a href="#" class="swiper-button-next" tabindex="0" role="button" aria-label="Next slide"><span>Next</span></a>
    </div> */}
          <SwiperSlide>

                <Microstory
                  image={placeholer}
                  content="“My steps were in bad shape and it became a hazard. They helped me find the most affordable option without sacrificing quality.”"
                  author="Rabecca"
                  city="Monroe, NJ"
              />
          </SwiperSlide>
          <SwiperSlide>
                <Microstory
                  image={placeholer}
                  content="“Great work. Completed my patio in just 3 days amd provided me with a lot of options for drainage.”"
                  author="Ronaldo"
                  city="Woodbridge, NJ"
              />
          </SwiperSlide>
          <SwiperSlide>
                <Microstory
                  image={placeholer}
                  content="“Agnaldo gave me an estimate that was much lower than the other contractors, plus the 5 percent discount for following on social media made it very affordable, especially for the quality.”"
                  author="Daquan"
                  city="Hoboken, NJ"
              />
            </SwiperSlide>
          <SwiperSlide>
                <Microstory
                  image={placeholer}
                  content="“I had pavers instlaled on patio a few months ago. They've made it through the winter and no complaints."
                  author="Moe"
                  city="Asbury Park, NJ"
              />
            </SwiperSlide>
      
      </Swiper>
      </div>
  );
};
export default Slider;