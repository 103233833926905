import React from 'react';
import PageHeader from '../layout/PageHeader';

export const Contact = () => {
    return (
        
        <div id="main">
            <PageHeader title="Contact Us" desc="Find OGC contact information for inquires related to free quotes, appointments, materials, and more."></PageHeader>
            
            
        </div>
    )
}