import React from 'react';
import PageHeader from '../layout/PageHeader';
import '../../styles/pages/Services.scss'
import { Link } from 'react-router-dom';
//service pages
import Concrete from '../pages/services/Concrete'
export const Services = () => {
    return (
        <div id="main">
            <PageHeader title="Services" desc="We install and renovate pavers, asphalt, facades, concrete, and more. This includes patios, steps, poolsides, foundations, and driveways!"></PageHeader>
            <section class="wrapper intro main">
            <p>OGC offers a variety of services in serveral materials. Click on one of the options below to get more information on each type, and get an idea of which option is right for you. If you have any questions regarding custom project, submit your inquiry <Link to="/#contact">here</Link> or call <a href="tel:+1 (908) 291-8829">+1 (908) 291-8829</a>.</p>
            </section>
            <section class="wrapper">
                <div class="flex-row">
               
                    <Link to="/services/concrete" compoment={ Concrete } class="item">
                        <img src="https://ephenry.com/wp-content/uploads/2020/05/Rev01-DJI_0776-205x205.jpg" alt="" data-lazy-src="https://ephenry.com/wp-content/uploads/2020/05/Rev01-DJI_0776-205x205.jpg" class="lazyloaded" data-was-processed="true"/><noscript><img src="https://ephenry.com/wp-content/uploads/2020/05/Rev01-DJI_0776-205x205.jpg" alt=""/></noscript>
                        <div class="name">Concrete</div>

                    </Link>
                    <Link to="/services/pavers" class="item">
                        <img src="https://ephenry.com/wp-content/uploads/2019/05/Rev2-EP_H9859-1-e1554484301280-205x205.jpg" alt="333" data-lazy-src="https://ephenry.com/wp-content/uploads/2019/05/Rev2-EP_H9859-1-e1554484301280-205x205.jpg" class="lazyloaded" data-was-processed="true"/><noscript><img src="https://ephenry.com/wp-content/uploads/2019/05/Rev2-EP_H9859-1-e1554484301280-205x205.jpg" alt="333"/></noscript>
                        <div class="name">Pavers</div>

                    </Link>
                    <Link to="/services/patios" class="item">
                        <img src="https://ephenry.com/wp-content/uploads/2018/01/Ep-Henry-Patio-Rev-Rev2-EP_H2291-205x205.jpg" alt="EP Henry patios" data-lazy-src="https://ephenry.com/wp-content/uploads/2018/01/Ep-Henry-Patio-Rev-Rev2-EP_H2291-205x205.jpg" class="lazyloaded" data-was-processed="true"/><noscript><img src="https://ephenry.com/wp-content/uploads/2018/01/Ep-Henry-Patio-Rev-Rev2-EP_H2291-205x205.jpg" alt="EP Henry patios"/></noscript>
                        <div class="name">Patios</div>

                    </Link>
                    <Link to="/services/walkways" class="item">
                        <img src="https://ephenry.com/wp-content/uploads/2018/02/EP_H1832-PG-20-205x205.jpg" alt="Bristol Stone I Sonoran, Random Installation; 6 x 9 Old Towne Cobble Charcoal border" data-lazy-src="https://ephenry.com/wp-content/uploads/2018/02/EP_H1832-PG-20-205x205.jpg" class="lazyloaded" data-was-processed="true"/><noscript><img src="https://ephenry.com/wp-content/uploads/2018/02/EP_H1832-PG-20-205x205.jpg" alt="Bristol Stone I Sonoran, Random Installation; 6 x 9 Old Towne Cobble Charcoal border"/></noscript>
                        <div class="name">Walkways</div>

                    </Link>
                    <Link to="/services/walls" class="item">
                        <img src="https://ephenry.com/wp-content/uploads/2018/01/Ep-Henry-Walls-Rev-EP_H7764-205x205.jpg" alt="Ep Henry Walls" data-lazy-src="https://ephenry.com/wp-content/uploads/2018/01/Ep-Henry-Walls-Rev-EP_H7764-205x205.jpg" class="lazyloaded" data-was-processed="true"/><noscript><img src="https://ephenry.com/wp-content/uploads/2018/01/Ep-Henry-Walls-Rev-EP_H7764-205x205.jpg" alt="Ep Henry Walls"/></noscript>
                        <div class="name">Walls</div>

                    </Link>
                    <Link to="/services/pooldecks" class="item">
                        <img src="https://ephenry.com/wp-content/uploads/2018/01/Ep-Henry-Pool-Deck-Rev-DJI_0194-205x205.jpg" alt="" data-lazy-src="https://ephenry.com/wp-content/uploads/2018/01/Ep-Henry-Pool-Deck-Rev-DJI_0194-205x205.jpg" class="lazyloaded" data-was-processed="true"/><noscript><img src="https://ephenry.com/wp-content/uploads/2018/01/Ep-Henry-Pool-Deck-Rev-DJI_0194-205x205.jpg" alt=""/></noscript>
                        <div class="name">Pool Decks</div>

                    </Link>
                    <Link to="/services/driveways" class="item">
                        <img src="https://ephenry.com/wp-content/uploads/2018/01/Ep-Henry-Driveways-Rev-DJI_0085-205x205.jpg" alt="EP Henry driveways" data-lazy-src="https://ephenry.com/wp-content/uploads/2018/01/Ep-Henry-Driveways-Rev-DJI_0085-205x205.jpg" class="lazyloaded" data-was-processed="true"/><noscript><img src="https://ephenry.com/wp-content/uploads/2018/01/Ep-Henry-Driveways-Rev-DJI_0085-205x205.jpg" alt="EP Henry driveways"/></noscript>
                        <div class="name">Driveways</div>

                    </Link>
                    <Link to="/services/firepits" class="item">
                        <img src="https://ephenry.com/wp-content/uploads/2018/02/Ep-Henry-Outdoor-Living-Rev-_DSCSWfirepitChapCapPatio_R-205x205.jpg" alt="" data-lazy-src="https://ephenry.com/wp-content/uploads/2018/02/Ep-Henry-Outdoor-Living-Rev-_DSCSWfirepitChapCapPatio_R-205x205.jpg" class="lazyloaded" data-was-processed="true"/><noscript><img src="https://ephenry.com/wp-content/uploads/2018/02/Ep-Henry-Outdoor-Living-Rev-_DSCSWfirepitChapCapPatio_R-205x205.jpg" alt=""/></noscript>
                        <div class="name">Fire Pits</div>

                    </Link>
                    <Link to="/services/borders" class="item">
                        <img src="https://ephenry.com/wp-content/uploads/2013/01/2018-08-13_11-44-28-205x205.jpg" alt="Borders and Edges" data-lazy-src="https://ephenry.com/wp-content/uploads/2013/01/2018-08-13_11-44-28-205x205.jpg" class="lazyloaded" data-was-processed="true"/><noscript><img src="https://ephenry.com/wp-content/uploads/2013/01/2018-08-13_11-44-28-205x205.jpg" alt="Borders and Edges"/></noscript>
                        <div class="name">Borders and Curbs</div>

                    </Link>
                    <Link to="/services/steps" class="item">
                        <img src="https://ephenry.com/wp-content/uploads/2019/04/Cast-Stone-Wall-Single-Gallery-Rev2-EP_H4831-205x205.jpg" alt="" data-lazy-src="https://ephenry.com/wp-content/uploads/2019/04/Cast-Stone-Wall-Single-Gallery-Rev2-EP_H4831-205x205.jpg" class="lazyloaded" data-was-processed="true"/><noscript><img src="https://ephenry.com/wp-content/uploads/2019/04/Cast-Stone-Wall-Single-Gallery-Rev2-EP_H4831-205x205.jpg" alt=""/></noscript>
                        <div class="name">Steps</div>

                    </Link>
                    <Link to="/services/facades" class="item">
                        <img src="https://ephenry.com/wp-content/uploads/2018/08/EP_H6318-PG-37-1024x682-1-1-205x205.jpg" alt="" data-lazy-src="https://ephenry.com/wp-content/uploads/2018/08/EP_H6318-PG-37-1024x682-1-1-205x205.jpg" class="lazyloaded" data-was-processed="true"/><noscript><img src="https://ephenry.com/wp-content/uploads/2018/08/EP_H6318-PG-37-1024x682-1-1-205x205.jpg" alt=""/></noscript>
                        <div class="name">Facades</div>

                    </Link>
                    <Link to="/services/asphalt" class="item">
                        <img src="https://ephenry.com/wp-content/uploads/2018/01/Ep-Henry-Permeable-Paver-Rev-EP_H3013-205x205.jpg" alt="Ep Henry Permeable Paver" data-lazy-src="https://ephenry.com/wp-content/uploads/2018/01/Ep-Henry-Permeable-Paver-Rev-EP_H3013-205x205.jpg" class="lazyloaded" data-was-processed="true"/><noscript><img src="https://ephenry.com/wp-content/uploads/2018/01/Ep-Henry-Permeable-Paver-Rev-EP_H3013-205x205.jpg" alt="Ep Henry Permeable Paver"/></noscript>
                        <div class="name">Asphalt</div>

                    </Link>
                </div>
            </section>
            
            
        </div>
    )
}